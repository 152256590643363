@import url(https://fonts.googleapis.com/css?family=Nunito:300,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);
html {
	overflow: -moz-scrollbars-none !important;
	max-width: 100%;
	overflow-x: hidden;

}

body {
  font-family: 'Work Sans', sans-serif;
  font-family: 'Merriweather', serif;
  font-family: 'Noto Serif', serif;
  font-family: 'Noto Serif JP', serif;
  font-family: 'Noto Serif Telugu', serif;


  background-color:  white;
	font-size: 16px;
	scrollbar-width: none !important;
  margin:0px;
  overflow-x: hidden;
	-ms-overflow-style: none;
}
body p{
  line-height: 33px;
}

/*----------------------------NAV BAR-----------------------------*/

.show {display: block;}

.dropbtn {
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  text-align: center;
}

.dropdown-content {
  display: none;
  background-color: white;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-top: 0.5rem;
  text-align: center;

}

.dropdown-content .a {
  color: black;
  padding: 13px 14px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {display: block;}


.navbar-left img{
  width: 110px;
  height: 50px;
	padding: 1.5rem 0rem 0rem 1rem;
}
.navbar-left{
	float: left;
}

.navbar-right{
	float: right;
	padding: 1.3rem 1rem 0rem 0rem;
}

.navbar-right{
  font-family: 'Noto Serif JP', serif;
}
.fa-bars {
  color: black;
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 960px) {

  .nav-links {
    color: black;
    display: grid;
    text-decoration: none;
    padding: 0rem 0.7rem;
    padding: 12px;
    font-size: 16px;
   
    cursor: pointer;
  }

  .nav-links:hover {
    border-bottom: none;
    color: #593b26;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.2s ease-out;
  }


  .nav-menu {
    display: inline-block;
    grid-gap: 10px;
    list-style: none;
    margin-right: 2rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .nav-menu li{
    display: inline-block;
    font-size: 17px;
    padding: 16px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    display: flex;
    flex-wrap: nowrap;
  }
  


}
@media screen and (max-width: 960px) {

  .nav-menu {
    padding-left: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 0%;
    height: auto;
    position: absolute;
    top: 70px;
    opacity: 1;
    right:0%;
    left:100%;
    transition: all 0.5s ease;
    }

  .nav-menu.active {
    background: transparent;
    opacity: 1;
    transition: all 0.5s ease;
    list-style: none;
    height: auto;
    overflow: hidden;
    width: 100%;
    left:0%;
  }

  .nav-links {
    display:flex;
    background-color: white;
    font-size: 1.2rem;
    font-weight: lighter;
    color: black;
    text-decoration: none;
    padding: 1.5rem;
    justify-content: center;
  }

  .nav-links:hover {
    color: #593b26;
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
    transition: 0.3s;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 50%);
            transform: translate(-100%, 50%);
    font-size: 1.8rem;
    padding: 0.5rem 1.5rem 0rem 0rem;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }
}



@media screen and (max-width: 500px) {

	    .nav-links {
	      font-size: 16px;
	    }
}


/*----------------------------NAV BAR-----------------------------*/

.navbar {
  margin: 0;
  width: 100%;
  background-color: white;
	color: black;
  position: fixed;
  z-index: 500;
	top: 0;
	transition: top 0.3s;
	display: inline-block;
	list-style: none;
	padding-right: 1em;
	box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.5);
	height: 6rem;
}

.navbar-left{
	float: left;
}

.navbar-right{
	float: right;
	padding: 1.3rem 1rem 0rem 0rem;
}

.fa-bars {
  color: black;
}

.menu-icon {
  display: none;
}

.dropbtn {
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  color: black;
  text-decoration: none;
  padding: 0rem 0.7rem 0.7rem 0.7rem;
  text-align: center;
  font-size: 16px;
}
.dropbtn:hover {
  border-bottom: none;
  color: #593b26;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.2s ease-out;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  background-color: white;
  position:absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content .a {
  color: black;
  padding: 13px 14px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}


@media screen and (min-width: 960px) {

  .nav-links {
    color: black;
    display: grid;
    text-decoration: none;
    padding: 0rem 0.7rem 0.7rem 0.7rem;
    text-align: left;
    font-size: 16px;
  }

  .drop{
    font-size: 13px;

  }

  .nav-links:hover {
    border-bottom: none;
    color: #593b26;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.2s ease-out;
  }

  .nav-menu {
    display: inline-block;
    grid-gap: 10px;
    list-style: none;
    margin-right: 2rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .nav-menu li{
    display: inline-block;
    font-size: 17px;
    padding: 16px;
  }
}
@media screen and (max-width: 960px) {

  .nav-menu {
    padding-left: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 0%;
    height: auto;
    position: absolute;
    top: 70px;
    opacity: 1;
    right:0%;
    left:100%;
    transition: all 0.5s ease;
    }
    

  .nav-menu.active {
    background: transparent;
    opacity: 1;
    transition: all 0.5s ease;
    list-style: none;
    height: auto;
    overflow: hidden;
    width: 100%;
    left:0%;
  }


  .nav-links {
    display:flex;
    background-color: white;
    font-size: 1rem;
    font-weight: lighter;
    color: black;
    text-decoration: none;
    padding: 1.3rem;
    justify-content: center;
    padding-left: 2.4rem;
  }

  .nav-links:hover {
    color: #593b26;
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
    transition: 0.3s;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 50%);
            transform: translate(-100%, 50%);
    font-size: 1.8rem;
    padding: 0.5rem 1.5rem 0rem 0rem;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }
  .dropdown {
    position: relative;
    display: contents;
  }
  #myDropdown{
    height: 100%;
  }
  .dropdown:hover .dropdown-content {
    display: contents;
    width:50%;
  }
  
  /* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn{
  border: none;
  outline: none;
  background-color: white;
  font-family: inherit;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1.29rem;

  display:inline-flex;
  text-decoration: none;
  text-align: left;
}


.dropdown-content .nav-links{
  font-size: 14px;
  padding: 0.3rem 0rem 0.3rem 0rem;
}

}

@media screen and (max-width: 500px) {
  .dropdown {
    position: relative;
    display: contents;
  }

  .dropdown-content {
    display: none;
    background-color: white;
    position:fixed;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content .a {
    color: black;
    padding: 13px 14px;
    text-decoration: none;
    display: block;
  }
  /* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  font-size: 15px;
  border: none;
  outline: none;
  background-color: white;
  font-family: inherit;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  display:inline-flex;
  text-decoration: none;
  text-align: left;
}
.dropdown-content .nav-links{
  font-size: 14px;
  padding: 0.3rem 0rem 0.3rem 0rem;
}

}

/*--------------------------Navbar PAGE--------------------------*/

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}



/*--------------------------LANDING PAGE--------------------------*/


.overlay {
    position:absolute;
    top:0;
    left:0;
    z-index:1;
}

.landing {
	height:45rem;
	width: 100%;
	background-image: url(/static/media/landing.c8a58149.jpg);
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	justify-content: center;
	background-attachment: fixed;
	color: white;
}

.landing h2 {
	font-size: 70px;
  padding: 9.5rem 0rem 0rem 3rem;
  font-weight: 500;
	line-height: 80px;
	width: 70%;
  margin: 0;

  font-family: 'Merriweather', serif;
  font-family: 'Noto Serif', serif;
  font-family: 'Noto Serif JP', serif;


}
.landing h3 {
	font-size: 40px;
  padding: 0rem 0rem 5rem 3rem;
  font-weight: 500;
	line-height: 70px;
	width: 70%;
  margin: 0;
  font-family: 'Noto Serif JP', serif;

}

.landing p{
  font-size: 20px;
  font-weight: 200;
  padding: 3rem 3rem 0rem 3rem;
  margin:0;
}

.button1 {
	cursor:pointer;
	border:none;
  margin: 2rem 50px;
	color: white;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: white;
  color: rgb(83, 83, 83);
  border: 2px solid white;
  border-radius: 1px;
  border-radius: 25px;

}

.button1:hover {
  color: black;
	transition:background-color 0.3s ease-in-out;
}

  .info{
    width:55rem;
   max-width:55rem;
  }


@media only screen and (max-width: 768px) {
  .landing {
    min-height: 70vh;
    background-size: 100rem;
    width: auto;
    height: 40rem;
  }

  .landing h2 {
    font-size: 50px;
    padding: 9.5rem 0rem 0rem 3rem;
    font-weight: 500;
    line-height: 80px;
    width: 70%;
  }
	.landing h3 {
		font-size: 30px;
	  padding: 0rem 0rem 4rem 3rem;
	  font-weight: 500;
		line-height: 60px;
		width: 70%;
	  margin: 0;
	}

  .landing p{
    font-size: calc(10px + (50 - 35) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 400;
  	padding: 0rem 2rem 0rem 3rem;
  }

  .info button{
    padding-left: 2rem;
  }

  .button1 {
  	font-size:12px;
  }
}

@media only screen and (max-width: 500px) {
  .landing {
    min-height: 90vh;
    width: auto;
  }

	.landing h2 {
		font-size: 50px;
	  padding: 9.5rem 0rem 0rem 3rem;
	  font-weight: 500;
		line-height: 60px;
		width: 70%;
	  margin: 0;
	}
	.landing h3 {
		font-size: 30px;
	  padding: 0rem 0rem 4rem 3rem;
	  font-weight: 500;
		line-height: 60px;
		width: 70%;
	  margin: 0;
	}


  .info button{
    padding-left: 2rem;
  }

  .button1 {
  	font-size:12px;
  }
}

@media only screen and (max-width: 300px) {

	.landing h2 {
		font-size: 40px;
	  padding: 9.5rem 0rem 0rem 2rem;
	  font-weight: 500;
		line-height: 40px;
		width: 70%;
	  margin: 0;
	}
	.landing h3 {
		font-size: 20px;
	  padding: 0rem 0rem 5rem 2rem;
	  font-weight: 500;
		line-height: 50px;
		width: 70%;
	  margin: 0;
	}

  .landing p{
    font-size: 14px;
  	font-weight: 200;
  	padding: 1rem 3rem 0rem 2rem;
    margin:0;
    line-height: 1.3rem;
		word-break: break-all;
		width: 20%;

  }

  .info button{
    padding-left: 2rem;
  }

  .button1 {
  	font-size:12px;
		padding-left: 0rem;

  }
}



/*----------------------------------------------------------------*/

/*----------------------------ABOUT US----------------------------*/

.about-section {
  display: inline-flex;
	flex-direction: column;
	min-height: 65vh;
  color: black;
  align-content: flex-start;
  text-align: left;
  margin:0;
  padding:0;
  padding-bottom: 100px;
}

.about-section h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 5rem 2rem 5rem;
  margin-bottom: 0;
}

.about-section p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 5rem 0rem 5rem;
  font-size: 20px;
  line-height: 33px;

}

.about-split{
  display:flex;
  resize: none;
}
.about-image{
  height: 30rem;
  width: 24rem;
  padding:7rem 6rem 0rem 1rem;
  margin:0;
}


.button2{
	cursor:pointer;
	border:none;
  margin-left: 5rem;
	color: rgb(38, 37, 37);
	padding:0.8rem 0rem 0.8rem 0rem;
	font-size:15px;
  width: 10rem;
  background-color: transparent;
  border: 1px solid rgb(30, 30, 30);
  border-radius: 25px;
  font-weight: 700;
}

.button2:hover {
  color: black;
  font-size:16px;
	transition:background-color 0.3s ease-in-out;
}

.arrow-image{
  size: 20;
}

@media(max-width:858px) {

  .about-split{
    display:block;
    resize: none;
  }
  .about-section p {
    padding: 1rem 4rem 0rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
  }
  .about-image{
    padding: 3rem 5rem 0rem 5rem;
    align-items: center;

  }
  .button2{
    font-size: 12px;
  }

}
@media only screen and (max-width: 500px) {

  .about-section{
    display:block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: inherit;
    padding-bottom: 3rem;
  }

  .about-split{
    display:block;
    text-align: center;
  }

  .about-section h4 {
  	padding: 3rem 2rem 0.5rem 2rem;
    margin:0;
    font-size: 30px;
    font-weight: 100;
  }
  .about-section p {
    padding: 1rem 2rem 0rem 2rem;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    font-weight: 100;
  }

  .about-image {
    padding: 0rem 0rem 0rem 0rem;
    align-items: center;
    height: 28rem;
    width: 23rem;
  }

  .button2 {
      text-decoration: none;
      font-size: 12px;
      align-items: center;
      padding:0.8rem 2rem 0.8rem 2rem;
      margin: 1.5rem 0rem;
      width: 15rem;

    }
}

@media only screen and (max-width: 400px) {

    .about-section{
      display:block;
      text-align: center;
      margin: 0;
      padding: 0;
      width: inherit;
      padding-bottom: 3rem;
    }

    .about-split{
      display:block;
      text-align: center;
    }

    .about-section h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 26px;
      font-weight: 100;
    }
    .about-section p {
      padding: 1rem 2rem 0rem 2rem;
      text-align: center;
      line-height: 21px;
      font-size: 11px;
      font-weight: 100;
    }

    .about-image {
      padding: 0rem 0rem 0rem 0rem;
      align-items: center;
      height: 28rem;
      width: 23rem;
    }

    .button2 {
        text-decoration: none;
        font-size: 11px;
        align-items: center;
        padding:0.8rem 2rem 0.8rem 2rem;
        margin: 1.5rem 0rem;
        width: 13rem;

      }
  }


/*---------------------------SERVICES HOME PAGE---------------------------*/

.service {
  margin: 0;
  background-color: white;
}
.service-text{
	display: flex;
	padding: 1rem 0 2rem 0;
}

.service-text h4 {
	width: 100%;
  text-align: center;
	font-size: 40px;
	font-weight: 200;
	padding: 4rem 0rem 0rem 0rem;
	resize:none;
  margin: 0;
}

.service-text p {
  text-align: left;
	font-size: 16px;
	font-weight: 200;
	padding: 4rem 10rem 3rem 0rem;
  resize:none;
  margin: auto;
}

.container{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
  flex-wrap: wrap;
}

.container h4{
	resize:none;
	font-size: 20px;
	font-weight: 200;
	padding: 2rem 2rem 0.5rem 2rem;
	resize:none;
	margin: 0;
}

.container:hover p{
	line-height:26px;
	font-size: 15px;
	font-weight: 300;
	padding: 2rem 1rem 0rem 1rem;
	resize: none;
	text-align: left;
}
.pre:hover h4, .pre:hover p{
	visibility: visible;
}
.present:hover h4, .present:hover p{
	visibility: visible;
}
.post:hover h4, .post:hover p{
	visibility: visible;
}

.item img{
	width: 100%;
	margin:0;
	position:absolute;
	visibility: visible;
	display: flex;
	justify-content: center;
	align-items: center;
}

.item:hover img{
	position:none;
	visibility: hidden;
}

.item {
  text-align: center;
  border-radius: 0px;
  border:2.3px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
	display:flex;
	resize: none;
	padding: 0;
	display :inline-block;
	background: white;
	width: 310px;
	height: 360px;
	margin :10px;

}

.item:hover{
  background: #F0EFED;
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  transition: all 0.5s ease 0s;
  -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.10);
  border-bottom: 5px solid #593b26;
}

.service-icons{
	font-size: 30px;
	color: black;
	display:inline-flex;
	justify-content: left;
	align-items: left;
	padding: 2rem 0rem 0rem 3rem;

}



@media only screen and (max-width: 980px) {
  .service h4{
    padding-bottom: 1rem;
  }
  .item{
    padding: 1rem 1rem 2rem 3rem;
    resize: none;
    display :inline-block;
    background: white;
    padding: 30px;
    width: 300px;
    height: 350px;
    margin :10px;
  }
  .man{
    margin-top: 3rem;
  }
  
  .test-container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
  .service-icons{
    font-size: 30px;
    padding: 1rem 0 0 0;
  }
  
  .item p{
    font-size:12px;
    line-height:26px;
    font-weight: 300;
  }
  .service {
    background-color: white;
    color: black;
    align-content: center;
    resize:none;
  }
  .item h4 {
    text-align: center;
    font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 200;
    padding: 1rem 0rem 1rem 0rem;
    width: auto;
    height: auto;
    resize:none;
    margin: auto;
  }
  }
  
  
  @media only screen and (max-width: 800px) {
  .service h4{
    padding-bottom: 1rem;
    font-size: 30px;
    text-align: center;
  
  }
  .item{
    padding: 1rem 1rem 2rem 1rem;
    resize: none;
    display :block;
    background: white;
    padding: 10px;
    width: 310px;
    height: 400px;
    margin :10px;
  }
  
  .test-container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
    display :block;
  
  }
  .service-icons{
    font-size: 30px;
  }
  
  .item p{
    font-size:14px;
    line-height:26px;
    font-weight: 200;
  }
  .service {
    background-color: white;
    color: black;
    align-content: center;
    resize:none;
  }
  .item h4 {
    text-align: center;
    font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 200;
    padding: 1rem 0rem 1rem 0rem;
  
  }
  
  }
  

@media only screen and (max-width: 980px) {
  .service h4{
    padding: 4rem 2rem 0 3rem;
  }

  .item{
    padding: 0rem 0rem 0rem 0rem;
    resize: none;
    display :inline-block;
    width: 290px;
    height: 350px;
  }

  .item p{
    font-size:12px;
    line-height:26px;
    font-weight: 300;
  }

  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
    width: auto;
    height: auto;
    resize:none;
    margin: auto;
		color: #ABD6FE;
  }

  .container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
}


@media only screen and (max-width: 500px) {
  .service-text{
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 0;
  }
  .service h4{
    padding-bottom: 1rem;
    font-size: 30px;
    padding: 2rem 0rem 1rem 0rem;
  }

  .service p{
    padding-bottom: 1rem;
    font-size: 16px;
    padding: 1rem 2rem 2rem 2rem;
    text-align: center;
  }
  .item{
    padding: 0rem 0rem 0rem 0rem;
    resize: none;
    display : block;
    width: 290px;
    height: 350px;
  }

  .item {
    height: 28rem;
    width: 23rem;
  }
  .container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
  .service-icons{
    font-size: 30px;
  }

  .item p{
    font-size:14px;
    line-height:26px;
    font-weight: 200;
  }
  .service {
  	background-color: white;
    align-content: center;
    resize:none;
  }
  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
    width: auto;
    height: auto;
    resize:none;
    margin: auto;
		color: #ABD6FE;
    display: block;
  }
}

@media only screen and (max-width: 300px) {
  .service h4{
    padding-bottom: 1rem;
    font-size: 32px;
    padding: 2rem 0rem 1rem 0rem;
  }

  .service p{
    padding-bottom: 1rem;
    font-size: 15px;
    padding: 1rem 2rem 2rem 2rem;
    text-align: center;
  }
  .item{
    padding: 1rem 1rem 2rem 1rem;
    resize: none;
    display :inline-block;
    padding: 10px;
    width: 210px;
    height: 350px;
    margin :10px;
  }

  .container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
  .service-icons{
    font-size: 27px;
  }

  .item p{
    font-size:11px;
    line-height: 20px;
    font-weight: 200;
  }
  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
  }
}

/*----------------------------------------------------------------*/

/*--------------------------Mid section --------------------------*/

.Mid1 {
  background-image: url(/static/media/editorial15.e94abcad.jpg);
	object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 20rem;
  object-position: center;
  object-position: 5px 90%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  padding: 0px;
  margin: 0px;
  color:white;
}

.Mid1 h4 {
	font-size: 30px;
  padding: 8rem 3rem 2rem 3rem;
  font-weight: 300;
  margin: 0;
	text-align: center;
}

@media only screen and (max-width: 500px) {
  .Mid1 h4 {
    font-size: 23px;
    padding: 9rem 3rem 2rem 3rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }
  
}
/*----------------------------------------------------------------*/

/*----------------------------Testimonials--------------------------*/


.testimonials {
	flex-direction: column;
	min-height: 60vh;
  background-color: white;
  color: white;
  margin:0;
  padding:0;
	display: flex;
}
.testimonials h4 {
	font-weight: 300;
	padding: 2rem 4rem 0rem 5rem;
  font-size: 44px;
  line-height: 40px;
	color: black;
}

.test-container  p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 2rem 0rem 3rem;
  font-size: 20px;
  line-height: 33px;
	color: black;
	justify-content: left;
	text-align: left;
}

.test-container h5 {
	font-weight: 300;
	padding: 0rem 4rem 0rem 3rem;
  font-size: 23px;
  line-height: 33px;
	color: black;
	justify-content: left;
	text-align: left;
}
.test-container{
	min-height: 65vh;
	margin-bottom: 4rem;
	display: flex;
}
.test-div{
	min-width: 150px;
  max-width: 410px;
	justify-content: left;
	text-align: left;
}

.test-underline{
	position: relative;
	border: none;
	height: 3px;
	width: 7rem;
	background: #DBEEFF;
	margin-bottom: 3rem;
	margin-left: 5rem;
}

.pic {
  display: flex;
  border: 4px solid white;
	padding-bottom: 1rem;
	justify-content: center;
	text-align: center;
}

.pic img {
  width: 10rem;
  height: 10rem;
}

.test-container {
  position:relative;
	justify-content: center;
	text-align: center;
}

.testimony-pic {
  display: inline-block;
  border: 4px solid white;
  border-radius: 50%;
  box-shadow: 0 0 4px 4px #A7D3FD;
  overflow: hidden;
  z-index: 1;
}
.testimonial-icons{
	font-size: 30px;
	color: black;
	display:inline-flex;
	justify-content: left;
	align-items: left;
	padding: 2rem 0rem 0rem 3rem;

}


/*----------------------------------------------------------------*/

/*---------------------------- Editorial Page ------------------------------*/

.editorial {
	display:flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding: 5rem 0 60px 0 ;
  text-align: left;
}

.editorial h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 5rem;
  margin-bottom: 0;
}

.editorial p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 0rem 2rem 5rem;
  line-height: 33px;
}

.editorial-split{
	margin: 0;
	padding: 3rem 0rem 0rem 0rem;
	text-align: center;
  display:flex;
	flex-direction:row;
  flex-wrap: wrap;
  justify-content: center;
	resize:none;
}

.editorial-img{
  height: 300px;
  width: 300px;
  resize: both;
  display: block;
  height: auto;
  padding: 0.5rem 0rem 0rem 0.5rem;
}
.project-overlay {
  position:absolute;
  top:0;
  left:0;
  z-index:1;
}

.project-overlay {
  opacity: 0;
  height: 300px;
  width: 430px;
  transition: .5s ease;
  background-color: transparent;
}


.editorial-img:hover .project-overlay {
  opacity: 1;
}

.editorial-text {
  color: white;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.editorial-container {
  position: relative;
  padding: 0rem 0rem 0rem 0rem;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.editorial-container:hover .project-overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.editorial-div{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
}

.editorial-div h4 {
	font-size: 30px;
	font-weight: 200;
	padding: 2rem 0rem 1rem 0rem;
  margin-bottom: 0;
}

.underline{
  position: relative;
  border: none;
  height: 2px;
  width: 7rem;
  background: black;
  margin-bottom: 50px;
}

@media(max-width:898px) {


  .project p {
    padding: 0rem 3rem 1rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }
}
@media only screen and (max-width: 500px) {

	.project-split .project-img{
    height: 280px;
    width: 300px;
    margin-right:0;
  }
  .project h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .project p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}


/*----------------------------------------------------------------------------*/

/*---------------------------- Editorial Template Page ------------------------------*/

.editorial-template{
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding: 5rem 0 60px 0 ;
  text-align: center;
}

.editorial-template h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 0rem;
  margin-bottom: 0;
}

.editorial-template p {
	font-weight: 100;
  font-size: 15px;
	padding: 0rem 6rem 0rem 6rem;
  margin: 0;
}

.editorial-div{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
}

.editorial-div h4 {
	font-size: 30px;
	font-weight: 200;
	padding: 0rem 0rem 0rem 0rem;
  margin-bottom: 0;
}

.editorial-underline{
  position: relative;
  border: none;
  height: 1px;
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
  background: black;
  margin-bottom: 40px;
  margin-top: 2rem;
}



@media only screen and (max-width: 500px) {

  .editorial-underline{
    position: relative;
    border: none;
    height: 1px;
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
    background: black;
    margin-bottom: 40px;
    margin-top: 2rem;
  }
  .editorial-template h4 {
    	padding: 5rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .editorial-template p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}


/*----------------------------------------------------------------------------*/

/*---------------------------- campaign Section ------------------------------*/


.campaign{
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding: 5rem 0 60px 0 ;
  text-align: left;
}

.campaign h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 4rem;
  margin-bottom: 0;
}

/*----------------------------------------------------------------------------*/

/*---------------------------- campaign Template Page ------------------------------*/

.campaign-template{
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding: 5rem 0 60px 0 ;
  text-align: center;
}

.campaign-template h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 0rem;
  margin-bottom: 0;
}

.campaign-template p {
	font-weight: 100;
  font-size: 15px;
	padding: 0rem 6rem 0rem 6rem;
  margin: 0;
}

.campaign-split{
	margin: 0;
	padding: 3rem 0rem 0rem 0rem;
	text-align: center;
  display:flex;
	flex-direction:row;
  flex-wrap: wrap;
  justify-content: center;
}

.campaign-img{
  height: 300px;
  width: 430px;
  padding: 0rem 0rem 0rem 0rem;
  resize: both;
  display: block;
  height: auto;
}

.project-overlay {
  opacity: 0;
  height: 300px;
  width: 430px;
  transition: .5s ease;
  background-color: transparent;
}

.campaign-img:hover .overlay {
  opacity: 1;
}

.campaign-text {
  color: white;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.campaign-div{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
}

.campaign-div h4 {
	font-size: 30px;
	font-weight: 200;
	padding: 0rem 0rem 0rem 0rem;
  margin-bottom: 0;
}

.campaign-underline{
  position: relative;
  border: none;
  height: 1px;
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
  background: black;
  margin-bottom: 40px;
  margin-top: 2rem;
}

@media(max-width:898px) {


  .campaign-template p {
    padding: 0rem 3rem 1rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }
}
@media only screen and (max-width: 500px) {

	.campaign-split .campaign-img{
    height: 280px;
    width: 300px;
    margin-right:0;
  }
  .campaign-template h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .campaign-template p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}


/*----------------------------------------------------------------------------*/

/*---------------------------- film Section ------------------------------*/


.film{
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding: 5rem 0 60px 0 ;
  text-align: left;
}

.film h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 4rem;
  margin-bottom: 0;
}

.film-split{
	margin: 0;
	padding: 3rem 0rem 0rem 0rem;
	text-align: center;
  display:flex;
	flex-direction:row;
  flex-wrap: wrap;
  justify-content: center;
}

.film-img{
  width: 330px;
  resize: both;
  display: block;
  height: auto;
  padding: 0.5rem 0rem 0rem 0.5rem;
}

.mth{
  object-position: 0% 5%;
}

#myVideo{
  overflow: hidden;
  margin-left:auto;
  margin-right:auto;
  bottom: 0rem;
  margin-bottom: 0rem;
}

iframe{
  position: absolute;
  top: 35rem;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 80%;
  margin-left:auto;
  margin-right:auto;
}

.video-div{
  height:40rem;
}

@media(max-width:898px) {
  iframe{
    position: absolute;
    top: 35rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
  }
}
@media(max-width:700px) {
  iframe{
    position: absolute;
    top: 35rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
  }

  .video-div{
    height:30rem;
  }
}
@media(max-width:500px) {

  iframe{
    position: absolute;
    top: 35rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 30%;
  }

  .video-div{
    height:20rem;
  }
}

/*----------------------------------------------------------------*/

/*----------------------------project------------------------------*/

.project {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding-bottom: 60px;
  text-align: left;
}

.project h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 5rem;
  margin-bottom: 0;
}

.project p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 0rem 2rem 5rem;
  line-height: 33px;
}

.project-split{
	margin: 0;
	padding: 3rem 0rem 0rem 0rem;
	text-align: center;
  display:flex;
	flex-direction:row;
  flex-wrap: wrap;
  justify-content: center;
}

.project-img{
  height: 400px;
  width: 400px;
  padding: 0.5rem 0rem 0rem 0.5rem;
  margin:0;
  resize: both;
  object-fit: cover;
}

.project-div{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
}

.project-div h4 {
	font-size: 30px;
	font-weight: 200;
	padding: 2rem 0rem 1rem 0rem;
  margin-bottom: 0;
}


@media(max-width:898px) {


  .project p {
    padding: 0rem 3rem 1rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }
}
@media only screen and (max-width: 500px) {

	.project-split .project-img{
    height: 280px;
    width: 300px;
    margin-right:0;
  }
  .project h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .project p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}

/*----------------------------------------------------------------*/

/*-----------------------------Project Page----------------------------*/

.projectpage-item {
  text-align: center;
  border-radius: 0px;
  border:2.3px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
	display:flex;
	resize: none;
	padding: 0;
	display :inline-block;
	background: white;
	width: 310px;
	height: 360px;
	margin :10px;

}

.projectpage-item:hover{
  background: #F0EFED;
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  transition: all 0.5s ease 0s;
  -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.10);
  border-bottom: 5px solid #593b26;
}

.projectpageitem img{
	width: 100%;
	margin:0;
	position:absolute;
	visibility: visible;
	display: flex;
	justify-content: center;
	align-items: center;
}

.projectpage-item:hover img{
	position:none;
	visibility: hidden;
}

.projectpage-item p{
  line-height:2px;
  font-size: 15px;
  font-weight: 300;
  padding: 4rem 0rem 1rem 0rem;
  resize: none;
}
.projectpage-item h4{
  text-align: center;
  font-size: calc(10px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 100;
  padding: 0.5rem 0rem 0.5rem 0rem;
  resize:none;
  margin-top: 0;
  margin-bottom: 0;
  color: #ABD6FE;
}

/*----------------------------------------------------------------*/

/*-----------------------------Portfolio Page----------------------------*/

.portfolio {
	display: flex;
	flex-direction: column;
	background-color: white;
	color: black;
  padding: 40px 0 60px 0;
  text-align: left;
  background-color: white;
}

.portfolio h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 5rem;
  margin-bottom: 0;
}

.portfolio p {
	font-weight: 100;
	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	padding: 0rem 0rem 2rem 5rem;
  line-height: 25px;
}

.portfolio-div{
	resize:none;
	display:flex;
	justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.portfolio-item {
  text-align: center;
  border-radius: 0px;
  overflow: hidden;
	display:flex;
	resize: none;
	padding: 0;
	display :inline-block;
	width: 100%;
	height: 320px;
	margin :0px;
}

.portfolio-container {
  height: 45rem;
  padding: 0rem 0rem 0rem 0rem;
  margin:1rem 0.5rem 1rem 0.5rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);

}
.portfolio-container p{
  padding: 0rem 0rem 0rem 2rem;
  text-align: left;
  width: 350px;
  font-weight: 100;
	font-size: calc(10px + (30 - 20) * ((100vw - 300px) / (1600 - 300)));

}
.portfolio-container h4{
  text-align: left;
  font-size: 24px;
  font-weight: 100;
  padding: 0.5rem 0rem 0.5rem 2rem;
}


.portfolio-button{
	cursor:pointer;
	border:none;
  margin: 2rem;
	color: rgb(38, 37, 37);
	padding:0.8rem 0rem 0.8rem 0rem;
	font-size:15px;
  width: 10rem;
  background-color: transparent;
  border: 1px solid rgb(30, 30, 30);
  border-radius: 25px;
  font-weight: 700;
}

.portfolio-button:hover {
  color: black;
  font-size:16px;
	transition:background-color 0.3s ease-in-out;
}

.portfolio-gallery{
  height: 400px;
  width: 400px;
  padding: 0rem 0rem 0rem 0.5rem;
  margin:0;
  resize: both;
  object-fit: cover;
}

.portfolio-img{
  height: 400px;
  width: 400px;
  padding: 0rem 0rem 0rem 0rem;
  margin:0;
  resize: both;
  object-fit: cover;
}

@media only screen and (max-width: 650px) {

  .portfolio{
    padding-top: 4rem;
  }
  .portfolio-container {
    height: 40rem;
    padding: 0rem 0rem 0rem 0rem;
    margin:1rem 1rem 1rem 1rem;
    width: 25rem;
    background-color: white;
  }
  
  .portfolio-item img{
    height: 530px;
    margin: 0;
    width: 100%;
  }

  .portfolio-item {
    border-radius: 0px;
    overflow: hidden;
    display:flex;
    resize: none;
    padding: 0;
    display :inline-block;
    width: 100%;
    height: 320px;
    margin :0px;
  }
  .portfolio-button{
    cursor:pointer;
    border:none;
    margin: 0 0 0 2rem;
    color: rgb(38, 37, 37);
    padding:0.6rem 0rem 0.6rem 0rem;
    font-size:13px;
    width: 8rem;
    background-color: transparent;
    border: 1.5px solid rgb(30, 30, 30);
    border-radius: 25px;
    font-weight: 700;
  }
  .portfolio-gallery{
    height: 400px;
    width: 400px;
    padding: 0rem 0rem 0rem 0rem;
    margin:0;
    resize: both;
    object-fit: cover;
  }
  .portfolio h4 {
    font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 200;
    padding: 2rem 0rem 1rem 2rem;
    margin-bottom: 0;
  }
  .portfolio-container h4{
    text-align: left;
    font-size: 24px;
    font-weight: 100;
    padding: 0.5rem 0rem 0.5rem 2rem;
  }

}


/*----------------------------------------------------------------*/

/*-----------------------------Process cycle----------------------------*/

.process {
	display: flex;
	flex-direction: column;
	background-color: #DBEEFF;
	color: black;
  text-align: left;

}

.process h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 0rem 0rem 2rem 5rem;
  margin-bottom: 0;
}

.process p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 0rem 0rem 5rem;
  line-height: 20px;
}

.process-img{
	width: 50%;
   position: absolute;
   right: 0px;
   height: 100%;
}

.process-split{
	display:flex;
}

@media only screen and (max-width: 898px) {
	.process-split{
		display: block;
		margin: auto;
		width: 100%;

	}
	.process-img{
		width: 100%;
	  position:relative;
	  height: 100%;
	}
}

/*----------------------------------------------------------------*/

/*-----------------------------CONTACT----------------------------*/



.contact h4 {
  font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.contact p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 0rem 0rem 0rem;
}

.contact-div{
	flex-direction: column;
	min-height: 60vh;
	color: black;
	align-content: flex-start;
	padding: 7rem 2rem 0rem 5rem;
	background-image: url(/static/media/editorial2.b0584b29.jpg);
	height:80%;
	width: 100%;
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	background-attachment: fixed;
	color: white;
}
.contact{
  flex-direction: column;
  min-height: 60vh;
  color: black;
  align-content: flex-start;
  padding: 2rem 2rem 0rem 5rem;
	background-image: url(/static/media/editorial2.b0584b29.jpg);
	height:80%;
	width: 100%;
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	background-attachment: fixed;
	color: white;
}

.contact-split{
  display:flex;
  resize: none;
}
.contact-split-left{
  padding: 2rem 1rem 6rem 1rem;
  display: inline;
  width:50%;
}
.contact-split-left h3{
  color: white;
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 0;
}
.contact-split-left p{
	font-size:15px;
}

.contact-msg{
  padding: 0;
  margin:0;
}

.input-group2 .bottom{
  line-height: 10px;
}
.input-row2{
  color: black;
  display: flex;
  font-size: 15px;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea, #contact button[type="submit"] { font:400 12px/16px "Open Sans", Helvetica, Arial, sans-serif; }

#contact {
  min-height: 60vh;
  text-align: left;
  width: 100%;
	padding:25px;
  padding-top: 5rem;
  resize: none;
}

#contact h3 {
	display: block;
	font-size: 30px;
	font-weight: 400;
}
#contact p {
	margin:5px 0 15px;
	display:block;
	font-size:13px;
  color: black;
}

fieldset {
	border: medium none !important;
	margin: 0 0 10px;
	min-width: 100%;
	padding: 0;
  resize: none;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
	width:70%;
	border:1px solid #CCC;
	background-color: #F9F9F9;
	margin:0 0 5px;
	padding:10px;
  resize: none;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}

#contact textarea {
	height:100px;
  resize:none;
}

#contact button[type="submit"] {
	cursor:pointer;
  width:73%;
	border:none;
	background:#363636;
	color:#FFF;
	padding:10px;
	font-size:15px;
}

#contact button[type="submit"]:hover {
	background:#593b26;
  color: black;
	transition:background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

#contact input:focus, #contact textarea:focus {
	outline:0;
	border:1px solid #999;
}
::-webkit-input-placeholder {
 color:#888;
}
:-moz-placeholder {
 color:#888;
}
::-moz-placeholder {
 color:#888;
}
:-ms-input-placeholder {
 color:#888;
}

.div-map{
  display: flex;
  padding: 0rem 4rem 4rem 0rem;
}
.map{
  width:350px;
  height:250px;
}

@media only screen and (max-width: 898px) {
  .contact-split{
    display:block;
    resize: none;
}
  #contact {
    min-height: 60vh;
    text-align: left;
    width: 100%;
    padding: 2rem 0rem 2rem 1rem;
  }
  .contact-split-left{
    padding: 0rem 7rem 0rem 0rem;
  }
  .contact-split-left h3{
    padding: 0rem 0rem 0rem 1rem;
    font-size: 30px;
    font-weight: 400;
  }
  .contact-split-left p{
    font-size:15px;
    padding: 0rem 0rem 0rem 1rem;
  }
  .map{
    width:350px;
    height:250px;
    margin: 1rem 3rem 0rem 1rem;
  }

}
@media only screen and (max-width: 898px) {
	.contact{
	  padding: 2rem 0 0rem 2rem;
    min-height: 70vh;
 
  }

  
  .contact-div{
	  min-height: 60vh;
    flex-direction: column;
    align-content: flex-start;
    padding: 7rem 2rem 0rem 2rem;
    height:80%;
    width: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
  }
}

/*------------------------------FOOTER----------------------------*/

.footer {
	text-align: center;
	background-color: white;
	color: black;
	line-height: 70px;
	height: 70px;
	text-align: center;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.5);

}


/*----------------------------------------------------------------*/

/*----------------------------SCROLL------------------------------*/

.MuiFab-secondary {
	background-color: blue !important;
	color: white !important;
}

/*----------------------------------------------------------------*/


/*----------------------------ABOUT PAGE CSS------------------------------*/

.aboutpage-CN {
	display: flex;
	flex-direction: column;
	color: black;
  resize: none;
  padding-top: 80px;
}
.header-image{
  text-align: left;
	display: flex;
	flex-direction: column;
}

.aboutpage-CN h4 {
	font-size: 40px;
	font-weight: 300;
	padding: 4rem 3rem 0rem 3rem;
	text-align: center;
}

.aboutpage-split{
  display:flex;
  font-size: 30px;
	font-weight: 600;
	padding: 1rem 2rem 5rem 2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

}
.aboutpage-text{
  padding: 0rem 2rem 0rem 1.5rem;
  text-align: left;
  margin-top: 0;
}

.aboutpage-text h4 {
	font-size: 30px;
  height: 10px;
  text-align: left;
  margin-top: 0;
  padding: 0rem 0rem 0rem 2rem;
}

.aboutpage-text p {
  font-size: 17px;
  width: 600px;
  resize: none;
  padding: 0rem 0rem 0rem 2rem;
  margin-top: 0;
  text-align: left;
}
.underline{
  position: relative;
  border: none;
  height: 2px;
  width: 7rem;
  background: white;
  margin-bottom: 50px;
}
#about-img{
  height: 470px;
  width:400px;
  resize: none;
}

@media only screen and (max-width: 768px) {

  .aboutpage-split{
    display:block;
    align-items: center;
    padding-left: 20px;
  }

  .aboutpage-text{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .aboutpage-CN h4 {
    font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 0rem 0rem 1rem 0rem;
  	font-weight: 300;
    margin-bottom: 0;
  }

  .aboutpage-text p{
    padding: 1rem 0rem 0rem 0rem;
    font-size: 18px;
    width: auto;

  }

  .aboutpage-split img{
    height: 20rem;
    width: 20rem;
    justify-content: center;
    padding: 0rem 0rem 3rem 0rem;

  }
 
}

@media only screen and (max-width: 500px) {

    .aboutpage-split{
      display:block;
      align-items: center;
      padding-top: 2rem;
    }
    .aboutpage-CN h4 {
      padding: 2rem 0rem 1rem 0rem;
      font-weight: 300;
      margin-bottom: 0;
    }
    .aboutpage-text p {
      font-size: 14px;
      width: auto;
      resize: none;
      padding: 2rem 0rem 0rem 0rem;
      margin-top: 0;
      text-align: left;
      line-height: 25px;
      font-weight: 100;
    }
    
    .aboutpage-CN p{
      font-size: 14px;
    }
    #about-img {
      padding: 0rem 0rem 0rem 0rem;
      align-items: center;
      height: 28rem;
      width: 23rem;
    }
  
}


@media only screen and (max-width: 300px) {

      .aboutpage-CN p{
        font-size: 11px;
        line-height: 20px;
        padding: 1rem 0rem 0rem 0rem;
      }
      .aboutpage-CN h4{
        font-size: 26px;
      }

      .aboutpage-split{
        display:block;
        justify-content: center;
      }


    .aboutpage-split img{
        height: 15rem;
        width: 15rem;
        justify-content: center;
        padding: 0rem 0rem 2rem 0rem;
        margin: 0;
      }

      .abt-back p {
      	padding: 1rem 2rem 0rem 2rem;
        font-size: 11px;
        margin: 0;

      }
      .abt-p h5{
        justify-content: center;
        padding: 0;

      }


      .aboutpage-text h4{
        text-align: center;
        margin: 0;
      }
      .aboutpage-text p{
        font-size: 11px;
        line-height: 20px;
        padding: 1rem 0rem 0rem 0rem;
        width: 210px;
        margin-top: 0;
        text-align: center;
      }


    }



/*----------------------------CLIENT PAGE CSS------------------------------*/



.Client {
	display: flex;
	flex-direction: column;
	min-height: 60vh;
	background-color: white;
	color: black;
  align-content: flex-start;
}

.Client h4 {
	font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.Client p {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}

.list-view {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,1);
    z-index: 1;
}


/*----------------------------CLIENT PAGE CSS------------------------------*/

.Service-CN {
	display: flex;
	flex-direction: column;
  align-content: flex-start;
  text-align: center;
  padding: 7rem 5rem 3rem 5rem;
}
.Service-CN h4 {
	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
  padding: 1rem 0rem 1rem 0rem;
	font-weight: 300;
  background-color: white;
  color:black;
  margin-bottom: 0;
}

.Service-CN p {
	font-size: 18px;
	font-weight: 80;
  font-style: italic;
  padding: 1rem 5rem 1rem 5rem;
}
.service-split-img{
  height: 350px;
  width: 290px;
  padding: 0rem 2rem 0rem 2rem;
}

.service-split{
  display:flex;
  color: black;
  resize: none;
  padding: 1rem 0rem 0rem 0rem;
  margin-right: 0;
}

.section-CN {
  display: flex;
	flex-direction: column;
  background-color: white;
  color: black;
  align-content: flex-start;
  padding: 1rem 0rem 2rem 0rem;
  margin-top: 0;
}
.section-CN h4 {
  font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 300;
  color: black;
  padding: 0rem 0rem 0rem 3rem;
  text-align: left;
  margin: 0;
}
.section-CN p {
	font-size: 18px;
  font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}

@media only screen and (max-width: 828px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: calc(8px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
  	font-weight: 80;
    font-style: italic;
    padding: 1rem 0rem 1rem 0rem;
  }
  .service-split{
    display:block;
  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    padding: 0rem 2rem 0rem 2rem;
  }
  .service-split img{
    height: 410px;
    width: 310px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 500px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: 14px;
  	font-weight: 80px;
    font-style: italic;
    padding: 1rem 0rem 1rem 0rem;
  }
  .service-split{
    display:block;
  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 14px;
    padding: 0rem 2rem 0rem 2rem;
  }
  .service-split img{
    height: 370px;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 300px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: 26px;
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: 11px;
  	font-weight: 20px;
    font-style: italic;
    line-height: 20px;
    padding: 1rem 0rem 0rem 0rem;
  }
  .service-split{
    display:block;
    padding: 0rem 0rem 0rem 0rem;
    margin: 0;

  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 11px;
    line-height: 20px;
    padding: 0rem 2rem 0rem 2rem;
  }
  .service-split img{
    height: 300px;
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-top: 0;
  }
}
/*----------------------------FAQ PAGE CSS------------------------------*/

.FAQ-CN {
	display: flex;
	flex-direction: column;
	min-height: 60vh;
	background-color: white;
	color: black;
  align-content: flex-start;
}

.FAQ-CN h3 {
	font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.FAQ-CN h4 {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.FAQ-CN p {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}



/*----------------------------FAQ PAGE CSS------------------------------*/


.faq-content {
  margin: 0;
  padding-left: 3em;
  padding-top:3rem;
  color: black;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px dotted #ccc;
  color: black;
}

.panel-title {
  font-size: 21px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 68px;
  display: block;
  cursor: pointer;
  color: black;
}

.panel-content {
  font-size: 20px;
  padding: 0px 14px 0px 0px;
  height: 0;
  color: black;
  z-index: -1;
  position: relative;
  opacity: 0;
  transition: .4s ease;
}

.panel:checked ~ .panel-content{
  height: auto;
  opacity: 1;
  padding: 14px;
  color: black;
}

.plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 5;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: .2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}

/*---------------------- Scroll behavior ---------------------*/


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.reveal {
  position: relative;
  opacity: 0;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.reveal.active {
  opacity: 1;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.active.fade-bottom {
  -webkit-animation: fade-bottom 0.8s  ease-in;
          animation: fade-bottom 0.8s  ease-in;
}
.active.fade-left {
  -webkit-animation: fade-left 0.6s ease-in;
          animation: fade-left 0.6s ease-in;
}
.active.fade-right {
  -webkit-animation: fade-right 0.6s ease-in;
          animation: fade-right 0.6s ease-in;
}
@-webkit-keyframes fade-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;

  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;

  }
}
@keyframes fade-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;

  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;

  }
}
@-webkit-keyframes fade-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-right {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/*----------------------------*/

.thankyou{
  	text-align: center;
  	display: flex;
  	flex-direction: column;
    background-image: url(/static/media/contact3.aca02f12.jpg);
    padding-top: 30px;
    color: black;
    background-size: 100rem;
    margin-top: 80px;

}

.thankyou h4{
  padding: 10rem;
  text-align: center;
  font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  .thankyou{
    	text-align: center;
    	display: flex;
    	flex-direction: column;
      padding-top: 80px;
      color: black;
      background-size: 100rem;
      margin-top: 80px;
      height: 40rem;
  }
  .thankyou h4{
    padding: 5rem;
    text-align: center;
    font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
  }

}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 7px;
  outline: none;
  border: none;
  cursor: none;
}

.btn--primary {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background-color: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}

